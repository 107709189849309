/**
 * The default portal theme
 * Note that the dark-theme is applied on top of this. See comments in dark theme scss file.
 * Also note that some elements have a .large class applied (e.g. login screen) for visibility.
 */

/*
  Global
  */
body {
  font-family: $font-family;
}

/*
  Spinner
*/
#pre-loading-spinner {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner-container {
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
  height: calc(100% - 56px);
  background-color: transparent;
  z-index: 7;
  background-color: $ait-white-50;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-image-inside {
  width: 80px;
  height: 80px;
  background-color: transparent;
  animation: rotate-logo 2s infinite ease-in-out;
  position: absolute;
  z-index: 8;
}

.spinner-image-outside {
  width: 80px;
  height: 80px;
  background-color: transparent;
  animation: rotate-spinner 1s infinite linear;
  position: absolute;
  z-index: 9;
}

@keyframes rotate-logo {
  0% {
    transform: perspective(160px) rotateY(0deg);
  }
  100% {
    transform: perspective(160px) rotateY(360deg);
  }
}
@keyframes rotate-spinner {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

/*
  Dialog
*/
.p-dialog {
  background-color: $ait-dark-grey;
  color: $ait-light-grey;
  font-size: 0.8rem;
  line-height: normal;
  border-radius: 4px;
  padding: 0;
  border: 1px solid $ait-light-grey;
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f;

  .p-dialog-content {
    padding: 1.5rem;

    .messages {
      background-color: $dv-dark-grey;
      padding: 1rem 1.5rem;
      margin: 0 -1.5rem 0.5rem -1.5rem;
      list-style-type: none;
    }
    .messages li {
      color: $info-font;
      font-size: 1rem;
    }
    .messages li .error {
      color: $error-font;
    }
    .messages li .warning {
      color: $warning-font;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: normal;
      color: $ait-orange;
      margin-bottom: 0 0 1.5rem 0;
      padding: 0;
    }
    
    h4 {
      font-size: 1.2rem;
      font-weight: normal;
      color: $ait-light-grey;
      margin: 1rem 0 0.5rem 0;
      padding: 0;
    }
  }

  .p-dialog-header {
    height: 0px;
  }

  .p-dialog-header-close {
    color: $ait-light-grey;
    top: 1.5rem;
    right: 1rem;

    :hover {
      color: $ait-white;
    }
  }
}
.large .p-dialog {
  font-size: 1.2rem;
}
.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.3);
}
app-login .p-dialog-mask,
app-reset-password .p-dialog-mask {
  background-color: transparent;
}

/*
  Toast
*/
.p-toast-message-custom {
  background-color: $ait-dark-grey;
  color: $ait-light-grey;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid $ait-light-grey;
  box-shadow: 0 11px 15px -7px #0003,0 24px 38px 3px #00000024,0 9px 46px 8px #0000001f;

  /*
    Heading
  */
  .p-toast-summary {
    font-weight: bold;
  }

  /*
    Close icon
  */
  .p-toast-icon-close {
    color: $ait-light-grey;
    padding: 0.35rem;
    font-weight: bold;
  }
}

/*
  Kanban cards (home component)
*/
.kanban-card {

  .p-card {
    font-family: $font-family;
  }

  .p-card-title {
    font-size: 1.2rem;
    font-weight: 500;
    color: $ait-dark-grey;
    margin-bottom: 0.4rem;
  }

  .p-card-subtitle {
    font-size: 1.1rem;
    font-weight: 500;
    color: $ait-dark-grey-75;
    margin-bottom: 0.4rem;
  }

  .p-card-content {
    font-size: 0.8rem;
    line-height: normal;
    font-weight: 400;
    color: $ait-dark-grey-75;
  }

  .kanban-footer {
    margin: 0;

    a {
      color: $primary;
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;

      &:hover,
      &:focus,
      &:visited {
        text-decoration: none;
      }
      span {
          padding-right: 0.5rem;
          // word wrap
          white-space: pre-wrap; /* CSS3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word; /* Internet Explorer 5.5+ */
      }
    }
  }
}

/**
 * Buttons
 */
.btn {
  text-transform: none;
  transition: filter 0.3s;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  line-height: normal;
  font-size: 0.9rem;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;

  :hover {
    text-decoration: none;
  }
}
.large .btn {
  font-size: 1.4rem;
}
.btn.flat {
  box-shadow: none;
  border: none;
  background-color: transparent;
}

/**
 * Checkobox
 */
.p-checkbox-box{
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid $ait-light-grey;
  border-radius: 2px;
  margin-right: 0.4rem;
}

/**
 * Dropdown
 */
.p-dropdown {
  width: 100%;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/**
 * Text input
 */
.p-inputtext {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: $ait-light-grey;
  border-bottom: 1px solid $ait-dark-grey;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
input.p-inputtext:focus-visible {
  outline: none;
}
.p-float-label {
  margin-top: 2rem;
  label {
    left: 0;
    transition: top 0.1s linear, font-size 0.1s linear;
  }
}
