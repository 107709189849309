@import "theme-variables.scss";

/**
 * Dark theme
 *
 * These modifications are applied on top of the default-theme to provide a
 * limited dark theme. It's been done this way because most of the product will be light themed
 * but a few small UI elements are dark themed (the Portal sidebar, and login screens).
 **/

.dark {
  /* Text input with float label */
  .p-inputtext {
    color: $dark-theme-font;
    border-bottom: 1px solid $ait-light-grey;
  }
  .p-inputtext:focus-visible {
    border-bottom: 2px solid $ait-orange;
  }
  .p-float-label input:focus ~ label,
  .p-float-label input.p-filled ~ label,
  .p-float-label textarea:focus ~ label,
  .p-float-label textarea.p-filled ~ label,
  .p-float-label .p-inputwrapper-focus ~ label,
  .p-float-label .p-inputwrapper-filled ~ label {
    color: $ait-light-grey;
    font-size: 1rem;
    top: -0.8rem;
  }
  .p-float-label input:focus ~ label,
  .p-float-label textarea:focus ~ label,
  .p-float-label .p-inputwrapper-focus ~ label {
    color: $ait-orange;
  }

  /**
  * Dropdown
  */
  .p-dropdown {
    border-bottom: 1px solid $ait-light-grey;

    .p-dropdown-items {
      background-color: $ait-dark-grey;
    }
    .p-dropdown-item {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
    .p-dropdown-item.p-highlight {
      color: $ait-orange;
    }
    .p-inputtext {
      border-bottom: none;
    }
  }
  p-dropdown:focus-within .p-dropdown {
    border-bottom: 2px solid $ait-orange;
  }

  /* Buttons */
  .btn {
    color: $ait-light-grey;
  }
  .btn {
    &:not([disabled]) {
      &:hover {
        color: $ait-light-grey;
        background-color: $ait-white-10;
      }
    }
  }
  .btn.primary {
    background-color: $ait-teal;
    color: #222;

    &:not([disabled]) {
      &:hover {
        background-color: $ait-teal;
        color: #222;
        filter: brightness(1.15);
      }
      &:focus {
        background-color: $ait-teal;
        color: #222;
        border: 1px solid $dark-theme-font;
      }
    }
    &:disabled {
      background-color: $ait-teal;
      color: #222;
      filter: brightness(0.7);
    }
  }
  .btn.info {
    background-color: $grey-400;
    color: #222;

    &:not([disabled]) {
      &:hover {
        background-color: $grey-400;
        color: #222;
        filter: brightness(1.15);
      }
      &:focus {
        background-color: $grey-400;
        color: #222;
        border: 1px solid $dark-theme-font;
      }
    }
    &:disabled {
      background-color: $grey-400;
      color: #222;
      filter: brightness(0.7);
    }
  }
}
