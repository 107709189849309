@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'styles/theme-variables.scss';

// PrimeNG
@import '../node_modules/primeng/resources/primeng.min.css';

// Custom styles
@import 'styles/default-theme.scss';
@import 'styles/dark-theme.scss';
@import 'styles/scroll-bar-modifications.scss';

// launcher iframe style
@import './app/components/launcher/launcher.component.scss';
