div#launchedAppWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

// this element is dynamically created, so the file is imported into styles.scss to be able to apply globally
div#launchedAppWrapper div.launchedApp, div#launchedAppWrapper iframe {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
div#launchedAppWrapper div.launchedApp {
  flex-grow: 0;
}

div#launchedAppWrapper div.launchedApp.forceHide {
  position: absolute;
  top: -2000px;
  left: -2000px;
}
