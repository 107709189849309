/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: $grey-100;
    box-shadow: inset 0 0 5px $grey-500;  
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-500; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $grey-800; 
  }